import ApplicationController from "./application_controller";
import { globalEventBus, GlobalEvents } from './../utils/global_events';

export default class extends ApplicationController {
  static values = { identifier: String }

  remove(event) {
    if (event.detail.id === this.element.dataset.id) {
      this.element.remove()
    }
  }

  removeElement(event) {
    event.preventDefault()

    const data = {
      html_id: this.element.dataset.id,
      identifier: this.hasIdentifierValue ? this.identifierValue : null
    }

    this.element.remove()
    globalEventBus.publishEvent(GlobalEvents.html.removed, data)
  }
}