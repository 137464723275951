import ApplicationController from './application_controller';
import axios from '../utils/axios';
import { ConfirmationModal } from '../utils/confirmation_modal';

export default class extends ApplicationController {
  static targets = ['searchForm']

  connect() {
    this.confirmModal = new ConfirmationModal({ text: this.confirmTextValue || 'Are you sure?' })
  }

  clickVerification(status, id) {
    this.confirmModal.show().then(result => {
      if (result.value) {
        axios.post(Routes.verification_super_admin_w9_form_path({ format: 'json', id: id }), { status })
          .then(resp => {
            if (status === 'approved!') {
              this.setApproved(id);
            } else {
              this.setRejected(id);
            }

            const successMsg = resp.data.message || 'Record has been updated successfully.'
            toastr.success(successMsg, 'Success')
          })
          .catch(resp => {
            const errorMsg = resp.response.data.message || 'There was an error processing the record.'
            toastr.error(errorMsg, 'Error')
          })
      }
    })
  }

  clickApprove(event) {
    event.preventDefault()
    this.clickVerification('approved!', event.currentTarget.dataset.id);
  }

  clickReject(event) {
    event.preventDefault()
    this.clickVerification('rejected!', event.currentTarget.dataset.id);
  }

  setApproved(id) {
    const $badgeTag = this.element.querySelector(`[data-w9-form="${id}"] [data-badge] h4`);
    
    $badgeTag.querySelector('[data-tooltip-target]').innerText = "Approved";
    $badgeTag.classList.remove("bg-warning");
    $badgeTag.classList.add("bg-success");
    this.disabledActions(id);
  }

  setRejected(id) {
    const $badgeTag = this.element.querySelector(`[data-w9-form="${id}"] [data-badge] h4`);
    
    $badgeTag.querySelector('[data-tooltip-target]').innerText = "Rejected";
    $badgeTag.classList.remove("bg-warning");
    $badgeTag.classList.add("bg-danger");
    this.disabledActions(id);
  }

  disabledActions(id) {
    const $buttonTag = this.element.querySelector(`[data-w9-form="${id}"] [data-action] button`);
    $buttonTag.classList.add('disabled');
    $buttonTag.attributes.disabled = true;
  }
}