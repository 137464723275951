import ApplicationController from './../application_controller';

export default class extends ApplicationController {
  static targets = ['industry', 'verificationFrequency']
  static values = { insurance: Object }

  connect() {
    this.insuranceRecord = this.insuranceValue.record
  }

  setRequired(event) {
    this.insuranceRecord.coverages[event.target.dataset.requirementType].required = event.target.checked
  }

  setValue(event) {
    this.insuranceRecord.coverages[event.target.dataset.requirementType].value = event.target.value
  }

  toggleStateInsuranceMinimum(event) {
    this.insuranceRecord.state_insurance_minimums[event.target.dataset.stateInsuranceType] = event.target.checked
  }

  payload() {
    return {
      insurance_requirement: {
        district_id: this.insuranceRecord.district_id,
        workflow_step_id: this.insuranceRecord.workflow_step_id,
        industry: this.industryTarget.value,
        verification_frequency: this.verificationFrequencyTarget.value,
        state_insurance_minimums: { ...this.insuranceRecord.state_insurance_minimums },
        coverages: {
          general_liability: { ...this.insuranceRecord.coverages.general_liability },
          auto_liability: { ...this.insuranceRecord.coverages.auto_liability },
          workers_compensation: { ...this.insuranceRecord.coverages.workers_compensation },
          garage_keepers: { ...this.insuranceRecord.coverages.garage_keepers },
          on_hook: { ...this.insuranceRecord.coverages.on_hook },
          cargo: { ...this.insuranceRecord.coverages.cargo },
          garage_liability: { ...this.insuranceRecord.coverages.garage_liability },
          umbrella_excess_liability: { ...this.insuranceRecord.coverages.umbrella_excess_liability },
          freight_broker: { ...this.insuranceRecord.coverages.freight_broker }
        }
      }
    }
  }

  submit(event) {
    event.preventDefault()
    this.submitting()

    $.ajax({
      type: 'PUT',
      url: this.insuranceValue.url,
      dataType: 'json',
      data: this.payload(),
      success: (resp) => { toastr.success(resp.message) },
      error: (resp) => { toastr.error(resp.responseJSON.errors) },
      complete: () => { this.submitted() }
    })
  }
}