import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';

export default class extends ApplicationController {
  static targets = ['template', 'additionalInsured', 'addPolicyCarrierButton', 'tosCheckbox', 'submitButton']
  static values = { showToggleable: Boolean }

  connect() {
    if (!this.showToggleableValue) {
      this.hideToggleableElement()
    } else {
      this.showToggleableElement()
      this.tosCheckboxTarget.hidden = true
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }

    Object.values(GlobalEvents.fileUpload).forEach((event) => {
      globalEventBus.subscribe(event, this.handleFileUploadEvent.bind(this));
    })
  }

  agreeTos(event) {
    if (event.target.checked) {
      setTimeout(() => {
        this.showToggleableElement()
        this.tosCheckboxTarget.hidden = true
      }, 500)
    } else {
      this.hideToggleableElement()
      this.tosCheckboxTarget.hidden = false
    }
  }

  addPolicyCarrier(event) {
    event.preventDefault()

    const index = Math.floor(Math.random() * 100000)
    const newCarrier = this.templateTarget.innerHTML.replace(/PolicyCarrierIndex/g, index)

    event.target.parentElement.insertAdjacentHTML('beforebegin', newCarrier)
  }

  removePolicyCarrier(event) {
    event.preventDefault()

    const elementId = event.target.dataset.contentId
    const carrierId = event.target.dataset.carrierId
    const element = document.getElementById(`${elementId}`)
    const destroyField = document.getElementById(`policy_carrier_${carrierId}_destroy`)

    if (destroyField !== null) { destroyField.value = true }

    element.remove()
  }

  handleFileUploadEvent(event) {
    switch (event.type) {
      case GlobalEvents.fileUpload.uploaded:
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = event.exceededMaxSize || event.exceededMaxPdfPages
        }

        break;
      case GlobalEvents.fileUpload.removed:
        if (this.hasSubmitButtonTarget) { this.submitButtonTarget.disabled = true }

        break;
    }
  }
}