import SubscriberController from './subscriber_controller';

export default class extends SubscriberController {
  get channel() {
    return 'NotificationsChannel';
  }

  received(data) {
    switch (data.status) {
      case 'error':
        toastr.error(data.message);
        break;
      case 'success':
        toastr.success(data.message);
        break;
      case 'warning':
        toastr.warning(data.message);
        break;
      default:
        toastr.info(data.message);
        break;
    }
  }
}