import ApplicationController from './../application_controller';
import { globalEventBus, GlobalEvents } from './../../utils/global_events';

export default class extends ApplicationController {
  static targets = ['destroyField']

  connect() {
    globalEventBus.subscribe(GlobalEvents.html.removed, this.handleRemoved.bind(this))
  }

  handleRemoved(event) {
    const id = event.html_id

    this.destroyFieldTargets.forEach((field) => {
      if (field.id === id) {
        field.value = true
      }
    })
  }
}