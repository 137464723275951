import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['number']
  
  connect() {
    this.status = this.element.dataset.status;
    this.url = this.element.dataset.url;
    this.getCountingNumber();
  }

  getCountingNumber() {
    var _this = this;

    var attributes = {};
    if (this.status !== 'all') { attributes['insurance_document_status'] = this.status };
    
    axios.get(this.url, { params: attributes })
      .then((resp) => {
        _this.numberTarget.innerText = resp.data.number;
        
      })
      .catch((error) => { toastr.error(error) });
  }
}