import SubscriberController from './subscriber_controller';

export default class extends SubscriberController {
  static targets = ['element']
  static values = { record: Object }

  connect() {
    super.connect()
    this.currentRecordId = this.recordValue.id
    this.currentRecordType = this.recordValue.type
  }

  get channel() {
    return 'ModelUpdatesChannel';
  }

  received(data) {
    if (!this.match(data)) { return }

    if (this.hasElementTarget) {
      this.elementTarget.innerHTML = data.value
    } else {
      this.element.innerHTML = data.value
    }
  }

  match(data) {
    return (this.currentRecordId === data.id && this.currentRecordType === data.type)
  }
}