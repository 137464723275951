import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import { validFileSize, toMB, toMBFixed } from '../utils/helpers';
import { countPages } from '../utils/pdf_utilities';

export default class extends ApplicationController {
  static targets = ['input', 'fileName', 'fileErrors']

  connect() {
    this.uploadedFile = null
    this.fileId = this.element.dataset.id
    this.maxPdfPagesValue = 5
    this.maxFileSizeValue = 20 // in MB
  }

  upload(event) {
    event.preventDefault()

    if (this.inputTarget.disabled) {
      this.inputTarget.disabled = false
      this.inputTarget.click()
      this.inputTarget.disabled = true
    } else {
      this.inputTarget.click()
    }
  }

  previewUpload(event) {
    event.preventDefault()
    globalEventBus.publishEvent(GlobalEvents.fileUpload.uploaded, { file: this.uploadedFile })
  }

  onFileUploaded(event) {
    event.preventDefault()

    this.uploadedFile = event.target.files[0]
    this.fileErrorsTarget.textContent = ''
    this.fileNameTarget.textContent = this.uploadedFile.name
    this.validateFile()

    const data = {
      file: this.uploadedFile,
      fileID: this.fileId,
      fileInvalid: this.fileErrorsTarget.textContent !== ''
    }

    globalEventBus.publishEvent(GlobalEvents.fileUpload.uploaded, data)
  }

  validateFile() {
    countPages(this.uploadedFile, (numOfPages) => {
      if (numOfPages > this.maxPdfPagesValue) {
        this.fileErrorsTarget.textContent = ` ${numOfPages} pages (WARNING! EXCEEDS MAX PAGES OF ${this.maxPdfPagesValue} PAGES)`
      }
    })

    if (!validFileSize(this.uploadedFile, this.maxFileSizeValue)) {
      this.fileErrorsTarget.textContent = ` ${toMBFixed(this.uploadedFile.size)}MB (WARNING! EXCEEDS MAX SIZE OF ${this.maxFileSizeValue} MB)`
    }
  }
}