import ApplicationController from './application_controller';
import axios from '../utils/axios';

window.insuranceOverviewPie = null;

export default class extends ApplicationController {
  static targets = ['canvas'];
  
  connect() {
    this.loadStatusStatistics();
  }

  loadStatusStatistics() {
    const $canvas = this.canvasTarget;

    if ($canvas) {
      const activeRGB = 'rgb(220 252 231)';
      const yellowRGB = 'rgb(254 249 195)';
      const redRGB = 'rgb(254 226 226)';

      const chartSetup = {
        type: 'pie',
        data: {
          labels: ['Active', 'Pending', 'Under Review', 'Inactive', 'Expired'],
          datasets: [
            {
              label: 'Overview Status',
              data: [],
              backgroundColor: [activeRGB, yellowRGB, yellowRGB, redRGB, redRGB],
              borderWidth: 1,
              fill: false
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }

      window.insuranceOverviewPie = new Chart($canvas, chartSetup);

      this.loadStatisticAsync(window.insuranceOverviewPie);
    }
  }

  loadStatisticAsync(chart) {
    axios.get(Routes.insurance_document_overview_network_statistics_path({ format: 'json' }))
      .then((resp) => {
        chart.data.datasets[0].data = resp.data;
        chart.update();
      });
  }
}