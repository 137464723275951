import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['readToa', 'toaCheckboxSection', 'electronicSignatureForm', 'declineButton', 'acceptButton']
  static values = { declineUrl: String }

  connect() {
    this.toaCheckboxSectionTarget.hidden = true
    this.electronicSignatureFormTarget.hidden = true
    this.toaSigned = false
    this.agreeToa = false
  }

  ok() {
    this.toaCheckboxSectionTarget.hidden = false
    this.readToaTarget.hidden = true
    this.declineButtonTarget.classList.remove('disabled')
  }

  checkToa(event) {
    if (event.target.checked) {
      this.agreeToa = true
      this.electronicSignatureFormTarget.hidden = false
    } else {
      this.agreeToa = false
      this.electronicSignatureFormTarget.hidden = true
    }
  }

  electronicallySigned(event) {
    if (event.detail.state === 'ready') {
      this.toaSigned = true
    } else {
      this.toaSigned = false
    }

    if (this.toaSigned && this.agreeToa) {
      this.acceptButtonTarget.classList.remove('disabled')
      this.declineButtonTarget.classList.add('disabled')
    } else {
      this.acceptButtonTarget.classList.add('disabled')
      this.declineButtonTarget.classList.remove('disabled')
    }
  }

  decline(event) {
    event.preventDefault()

    if (confirm('This means that you will not be able to complete the process. Are you sure you want to Decline?')) {
      event.target.innerHTML = 'Declining...'
      event.target.classList.add('disabled')

      Rails.ajax({
        type: 'POST',
        url: this.declineUrlValue,
        contentType: 'application/json',
        data: new URLSearchParams({}).toString(),
        success: (resp) => {
          toastr.warning('You have declined the Terms of Acceptance.')
          setTimeout(() => {
            window.location.href = resp.redirect_url
          }, 1000)
        },
        error: (resp) => {
          toastr.error(resp.error)
          event.target.classList.remove('disabled')
        },
        complete: () => { event.target.innerHTML = 'Decline' }
      })
    }
  }
}