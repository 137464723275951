import ApplicationController from "./application_controller";
import { globalEventBus, GlobalEvents } from "../utils/global_events";
import { simpleIndex } from '../utils/helpers';

export default class extends ApplicationController {
  static targets = ['template', 'container']
  static values = { identifier: String, index: String }

  add(event) {
    event.preventDefault()

    const id = simpleIndex()
    const content = this.templateTarget.innerHTML.replace(new RegExp(this.indexValue, 'g'), id)
    const data = {
      html_id: id,
      identifier: this.hasIdentifierValue ? this.identifierValue : null
    }

    this.containerTarget.insertAdjacentHTML('beforeend', content)
    globalEventBus.publishEvent(GlobalEvents.html.added, data)
  }
}