import ApplicationController from './application_controller';
import axios from '../utils/axios';

window.chartInsuranceDocument = null;

export default class extends ApplicationController {
  static targets = ['canvas'];
  
  connect() {
    this.loadStatusStatistics();
  }

  loadStatusStatistics() {
    const $canvas = this.canvasTarget;

    if ($canvas) {
      const times = 7;
      
      const activeRGB = 'rgb(220 252 231)';
      const yellowRGB = 'rgb(254 249 195)';
      const redRGB = 'rgb(254 226 226)';

      const chartSetup = {
        type: 'bar',
        data: {
          labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
          datasets: [
            {
              label: 'Active',
              data: [],
              backgroundColor: activeRGB,
              borderWidth: 1,
              hoverBackgroundColor: 'rgb(220 252 231 / 60%)',
            },
            {
              label: 'Pending',
              data: [],
              backgroundColor: yellowRGB,
              borderWidth: 1,
              hoverBackgroundColor: 'rgb(254 249 195 / 60%)',
            },
            {
              label: 'Need Review',
              data: [],
              backgroundColor: yellowRGB,
              borderWidth: 1,
              hoverBackgroundColor: 'rgb(254 249 195 / 60%)',
            },
            {
              label: 'Inactive',
              data: [],
              backgroundColor: redRGB,
              borderWidth: 1,
              hoverBackgroundColor: 'rgb(254 226 226 / 60%)',
            },
            {
              label: 'Expired',
              data: [],
              backgroundColor: redRGB,
              borderWidth: 1,
              hoverBackgroundColor: 'rgb(254 226 226 / 60%)',
            },
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }

      window.chartInsuranceDocument = new Chart($canvas, chartSetup);

      this.loadStatisticAsync('active', window.chartInsuranceDocument);
      this.loadStatisticAsync('need_review', window.chartInsuranceDocument);
      this.loadStatisticAsync('pending', window.chartInsuranceDocument);
      this.loadStatisticAsync('inactive', window.chartInsuranceDocument);
      this.loadStatisticAsync('expired', window.chartInsuranceDocument);
    }
  }

  loadStatisticAsync(state, chart, period) {
    const selectedPeriod = period || 'day_in_week';
    
    axios.get(Routes.insurance_status_statistic_network_statistics_path({ format: 'json', state, range_time: selectedPeriod }))
      .then((resp) => {
        const index = chart.data.datasets.findIndex((i) => i.label.toLowerCase().replace(" ", "_") === state);

        chart.data.labels = resp.data.labels;
        chart.data.datasets[index].data = resp.data.data;
        chart.update();
      });
  }

  changePeriodStatus(e) {
    const period = e.currentTarget.value;
    
    this.loadStatisticAsync('active', window.chartInsuranceDocument, period);
    this.loadStatisticAsync('need_review', window.chartInsuranceDocument, period);
    this.loadStatisticAsync('pending', window.chartInsuranceDocument, period);
    this.loadStatisticAsync('inactive', window.chartInsuranceDocument, period);
    this.loadStatisticAsync('expired', window.chartInsuranceDocument, period);
  }
}