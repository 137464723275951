import ApplicationController from './../application_controller';

export default class extends ApplicationController {
  static targets = ['form', 'checkout', 'cancelButton']

  connect() {
    this.formTarget.addEventListener('ajax:success', this.success.bind(this))
    this.formTarget.addEventListener('ajax:error', this.error.bind(this))
  }

  cancel() {
    this.formTarget.hidden = false
    this.cancelButtonTarget.hidden = true
    this.checkout.destroy();
  }

  success(event) {
    const [data, _status, _xhr] = event.detail
    this.initCheckout(data)
  }

  error(_event) {
    toastr.error('There was an error processing your payment. Please try again.')
  }

  async initCheckout(data) {
    const stripe = Stripe(data.api_key)
    this.checkout = await stripe.initEmbeddedCheckout({ clientSecret: data.secret })

    this.formTarget.hidden = true
    this.cancelButtonTarget.hidden = false

    this.checkout.mount(this.checkoutTarget)
  }
}