import ApplicationController from './application_controller';
import axios from '../utils/axios';

window.chartInsuranceTrend = null;

export default class extends ApplicationController {
  static targets = ['canvas'];
  
  connect() {
    this.loadStatusStatistics();
  }

  loadStatusStatistics() {
    const $canvas = this.canvasTarget;

    if ($canvas) {
      const activeRGB = 'rgb(220 252 231)';
      const yellowRGB = 'rgb(254 249 195)';
      const redRGB = 'rgb(254 226 226)';

      const chartSetup = {
        type: 'line',
        data: {
          labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
          datasets: [
            {
              label: 'Register',
              data: [],
              backgroundColor: activeRGB,
              borderWidth: 1,
              fill: false
            },
            {
              label: 'Update',
              data: [],
              backgroundColor: yellowRGB,
              borderWidth: 1,
              fill: false
            },
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }

      window.chartInsuranceTrend = new Chart($canvas, chartSetup);

      this.loadStatisticAsync('register', window.chartInsuranceTrend);
      this.loadStatisticAsync('update', window.chartInsuranceTrend);
    }
  }

  loadStatisticAsync(type, chart, period) {
    const selectedPeriod = period || 'day_in_week';
    
    axios.get(Routes.trend_statistic_network_statistics_path({ format: 'json', type, range_time: selectedPeriod }))
      .then((resp) => {
        const index = chart.data.datasets.findIndex((i) => i.label.toLowerCase().replace(" ", "_") === type);

        chart.data.labels = resp.data.labels;
        chart.data.datasets[index].data = resp.data.data;
        chart.update();
      });
  }

  changePeriodStatus(e) {
    const period = e.currentTarget.value;
    
    this.loadStatisticAsync('register', window.chartInsuranceTrend, period);
    this.loadStatisticAsync('update', window.chartInsuranceTrend, period);
  }
}