import ApplicationController from './../application_controller';
import { globalEventBus, GlobalEvents } from './../../utils/global_events';

export default class extends ApplicationController {
  static targets = ['insuranceDocTemplate', 'tosCheckbox', 'submitButton', 'insuranceDocuments', 'proofOfInsurances', 'docRequired']

  connect() {
    this.insuranceDocuments = {}
    this.proofOfInsurancesNum = 0
    this.submitButtonTarget.disabled = true
    this.hideToggleableElement()

    globalEventBus.subscribe(GlobalEvents.fileUpload.uploaded, this.handleFileUploadEvent.bind(this))
    Object.keys(GlobalEvents.html).forEach((event) => {
      globalEventBus.subscribe(GlobalEvents.html[event], this.handleDocumentUpdate.bind(this))
    })
  }

  agreeTos(event) {
    if (event.target.checked) {
      setTimeout(() => {
        this.showToggleableElement()
        this.tosCheckboxTarget.hidden = true
      }, 500)
    } else {
      this.hideToggleableElement()
      this.tosCheckboxTarget.hidden = false
    }
  }

  onPostSuccess(event) {
    const response = event.detail[0]

    toastr.success(response.message, 'Success')
    window.location.href = response.redirect_path
  }

  onPostError(event) {
    const response = event.detail[0]
    toastr.error(response.error, 'Error')
  }

  handleDocumentUpdate(event) {
    if (event.type === GlobalEvents.html.added) {
      this.documentAdded(event)
    } else if (event.type === GlobalEvents.html.removed) {
      this.documentRemoved(event)
    }
  }

  documentAdded(event) {
    if (event.identifier === 'insuranceDocument') {
      const docId = event.html_id
      this.insuranceDocuments[docId] = { file: undefined, invalid: false }
    } else if (event.identifier === 'proofOfInsurance') {
      this.proofOfInsurancesNum++
    }

    if (this.proofOfInsurancesNum > 0) {
      this.docRequiredTargets.forEach((doc) => {
        if (doc.innerHTML === doc.dataset.requiredText) {
          doc.innerHTML = ''
        }
      })
    }

    this.toggleSubmitButton()
  }

  documentRemoved(event) {
    if (event.identifier === 'insuranceDocument') {
      const docId = event.html_id
      delete (this.insuranceDocuments[docId])

      if (!this.noInsuranceDocuments && this.firstDocument.file !== undefined) {
        globalEventBus.publishEvent(GlobalEvents.fileUpload.uploaded, { file: this.firstDocument.file })
      }
    } else if (event.identifier === 'proofOfInsurance') {
      this.proofOfInsurancesNum--

      if (this.proofOfInsurancesNum === 0) {
        this.docRequiredTargets.forEach((doc) => {
          if (doc.innerHTML === '') {
            doc.innerHTML = doc.dataset.requiredText
          }
        })
      }
    }

    this.toggleSubmitButton()
  }

  handleFileUploadEvent(event) {
    this.insuranceDocuments[event.fileID] = { file: event.file, invalid: event.fileInvalid }
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    this.submitButtonTarget.disabled = this.invalidInsuranceDocuments
  }

  get noInsuranceDocuments() {
    return _.isEmpty(this.insuranceDocuments)
  }

  get invalidInsuranceDocuments() {
    if (this.proofOfInsurancesNum === 0) {
      return Object.values(this.insuranceDocuments).some((doc) => doc.file === undefined || doc.invalid) || this.noInsuranceDocuments
    } else {
      return false
    }
  }

  get firstDocument() {
    return Object.values(this.insuranceDocuments)[0]
  }
}