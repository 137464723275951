import { EventBus } from './event_bus';

export class GlobalEvents {
  static get fileUpload() {
    return {
      uploaded: 'file-upload',
      removed: 'file-removed',
    }
  }

  static get toggle() {
    return {
      label: 'toggle-label',
      checkbox: 'toggle-checkbox'
    }
  }

  static get imageCapture() {
    return {
      captured: 'image-captured',
      removed: 'image-removed',
    }
  }

  static get html() {
    return {
      removed: 'html-removed',
      added: 'html-added',
    }
  }
}

export const globalEventBus = new EventBus();