import ApplicationController from '../application_controller';
import consumer from '../../action_cable/consumer';

export default class extends ApplicationController {
  connect() {
    this.subscriber = consumer.subscriptions.create(
      { channel: this.channel },
      { received: this.received.bind(this) }
    );
  }

  disconnect() {
    if (this.subscriber) {
      consumer.subscriptions.remove(this.subscriber);
    }
  }

  get channel() {
    // This must be implemented by the subclass
    throw new Error('NotImplementedError');
  }
}