import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.element.addEventListener('ajax:success', this.successHandler.bind(this))
    this.element.addEventListener('ajax:error', this.errorHandler.bind(this))
  }

  successHandler(event) {
    const [data, _status, _xhr] = event.detail

    toastr.success(data.message)

    if (data.redirect_to) {
      this.redirectTo(data.redirect_to)
    }
  }

  errorHandler(event) {
    const [data, _status, _xhr] = event.detail

    toastr.error(data.error)

    if (data.redirect_to) {
      this.redirectTo(data.redirect_to)
    }
  }

  redirectTo(url) {
    window.location.href = url
  }
}