import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['employeeEmail', 'screeningProfileName', 'backgroundCheckProfileName']

  connect() {
    this.employeeId = null
    this.employeeEmail = null
    this.screeningProfileName = null
    this.backgroundCheckProfileName = null
    var _this = this;
    $(this.element).on('show.bs.modal', (event) => {
      this.employeeId = event.relatedTarget.dataset.employeeId
      _this.employeeEmailTarget.innerText = event.relatedTarget.dataset.email
      _this.screeningProfileNameTarget.innerText = event.relatedTarget.dataset.screeningProfileName
      _this.backgroundCheckProfileNameTarget.innerText = event.relatedTarget.dataset.backgroundCheckProfileName
    })
  }

  submit(event) {
    event.preventDefault()
    var employeeId = this.employeeId;
    axios.post(Routes.request_background_check_employee_path(this.employeeId))
      .then(resp => {
        $(`[data-target='#requestBackgroundCheckModal'][data-employee-id='${employeeId}']`).remove()
        toastr.success(resp.data.message)
        $(this.element).modal('hide')
      })
      .catch(resp => {
        toastr.error(resp.response.data.error)
      })
  }
}