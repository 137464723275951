const validFileSize = (file, maxSize) => {
  return toMB(file.size) <= parseFloat(maxSize)
}

const toMB = (sizeInBytes) => {
  return sizeInBytes / 1024 / 1024
}

const toMBFixed = (sizeInBytes) => {
  return parseFloat(toMB(sizeInBytes)).toFixed(2)
}

const fileToDataUrl = (file, callback) => {
  const reader = new FileReader()

  reader.onload = (event) => {
    callback(event.target.result)
  }

  reader.readAsDataURL(file)
}

const simpleIndex = () => { return Math.floor(Math.random() * 100000) }

export { validFileSize, toMB, toMBFixed, fileToDataUrl, simpleIndex }