import ApplicationController from './../application_controller';
import { globalEventBus, GlobalEvents } from './../../utils/global_events';
import { simpleIndex } from './../../utils/helpers';

export default class extends ApplicationController {
  static targets = ['formField']

  connect() {
    if (this.element.dataset.id === undefined) {
      this.element.dataset.id = simpleIndex()
    }
    this.formFieldTargets.forEach((field) => { field.disabled = true })

    globalEventBus.subscribe(GlobalEvents.fileUpload.uploaded, this.handleFileUploadEvent.bind(this))
    globalEventBus.publishEvent(GlobalEvents.html.added, this.htmlData)
  }

  get htmlData() {
    return {
      html_id: this.element.dataset.id,
      identifier: 'insuranceDocument'
    }
  }

  handleFileUploadEvent(event) {
    if (event.fileID === this.element.dataset.id) {
      this.formFieldTargets.forEach((field) => { field.disabled = event.fileInvalid })
    }
  }
}