import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['number']
  
  connect() {
    this.status = this.element.dataset.status;
    this.getCountingNumber();
  }

  getCountingNumber() {
    var _this = this;

    var attributes = {};
    if (this.status !== 'all') { attributes['status'] = this.status };

    axios.get(Routes.count_status_super_admin_cois_path({ format: 'json', ...attributes }))
      .then((resp) => {
        _this.numberTarget.innerText = resp.data.number;
        
      })
      .catch((error) => { toastr.error(error.response.data.error) });
  }
}